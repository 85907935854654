.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.slide-container {
  width: 100%;
  margin: auto; }

h3 {
  text-align: center; }

.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 300px;
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.each-fade {
  display: flex;
}

.each-fade .image-container {
  width: 100%;
  overflow: hidden;
}

.each-fade .image-container img {
  width: 100%;
}

.caption-container {
  width: 100%;
  overflow: hidden;

  background: #b0dbdc;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  display: flex;
  justify-content: center;
  align-items: center; 
}

.caption-text {
  width: 100%;
  overflow: hidden;
  padding: 10px;
}

.each-fade h2 {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  background: #b0dbdc;
}

.inside-bullets {
  display: inline-block;
   list-style-position: inside !important; 
}